/**
* Get the URL parameter value
*
* @param  name {string} パラメータのキー文字列
* @return  url {url} 対象のURL文字列（任意）
*/
function getParam(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}


//menu|メニュー表示時のスクロール止め
$(function(){
    // bodyへのoverflow設定：ここで背景のスクロールを固定する。
    var menuBtnCheck = document.getElementById('drawer-checkbox');
    var menuBar = document.getElementById('drawer-open');
    var body = document.getElementsByTagName('body');

    menuBar.onclick = function() { // メニューバーをクリックした時
        if (menuBtnCheck.checked) {
            document.body.style.overflow = 'auto';
        } else {
            document.body.style.overflow = 'hidden';
        }
    }
});

/* アンカーリンクのスムーススクロール */
$('a[href^="#"]').on('click', function() {
    var speed = 400;
    var offset = 0;
    var $this = $(this);
    var href = $this.attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top - offset
    $('body,html').animate({scrollTop:position}, speed, 'swing');
    return false
});


/**
 * スクロールするとフェードイン + ある要素が見えたらSticky
 * @param {String} element 
 * @param {String} target 
 * @param {Nunber} offset 
 */
function scroll_to_fadein_sticky(element,target,offset) {
    var $elm = $(element);
    var $btn = $elm.children();
    var offset = typeof offset !== 'undefined' ?  offset : 0;
    $btn.hide();
    $(window).on('scroll',function () {
        if ($(this).scrollTop() > 50) {
            $btn.fadeIn();
        } else {
            $btn.fadeOut();
        }
        var $w = $(window);
        var $stickyTarget = $(target).offset().top;
        var $trigger = $w.scrollTop() + $w.height();
        var sticky_scrolled = $trigger > $stickyTarget ? true : false;
        if(sticky_scrolled) {
            var $stickyPosition = $trigger - $stickyTarget + offset;
            //$elm.css('bottom', $stickyPosition);
            $elm.addClass('top_position');
        } else {
            //$elm.attr('style','');
            $elm.removeClass('top_position');
        }
    });
}
scroll_to_fadein_sticky('.c-scroll-top','.l-footer');


//お知らせカテゴリーページカレント
$(function(){
    $(".information_category__category_list__child a").each(function(){
        if(this.href == location.href) {
            $(this).addClass("current");
        }
    });
});